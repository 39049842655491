function navigation () {
    const navLinks = document.querySelectorAll('.navigation__item');
    const currentUrl = window.location.pathname;

    if(currentUrl.indexOf('universities')!=-1) {
        navLinks[1].classList.add('navigation__item--active');
        navLinks[4].classList.add('navigation__item--active');
    } else if(currentUrl.indexOf('history')!=-1) {
        navLinks[2].classList.add('navigation__item--active');
        navLinks[5].classList.add('navigation__item--active');
    } else {
        navLinks[0].classList.add('navigation__item--active');
        navLinks[3].classList.add('navigation__item--active');
    }
}

module.exports = navigation;